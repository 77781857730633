var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("icon-clicked")
        },
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_4369_3428)" } }, [
        _c("path", {
          attrs: {
            d: "M19 8.5L12 15.5L5 8.5",
            stroke: "#A7ADB6",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_4369_3428" } }, [
          _c("rect", { attrs: { width: "24", height: "24", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }