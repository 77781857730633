<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('icon-clicked')"
  >
    <g clip-path="url(#clip0_4369_3428)">
      <path
        d="M19 8.5L12 15.5L5 8.5"
        stroke="#A7ADB6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4369_3428">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'InputChevronDown',
}
</script>
